define("@glimmer/vm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TemporaryRegister = _exports.SavedRegister = _exports.$v0 = _exports.$t1 = _exports.$t0 = _exports.$sp = _exports.$s1 = _exports.$s0 = _exports.$ra = _exports.$pc = _exports.$fp = void 0;
  _exports.isLowLevelRegister = isLowLevelRegister;
  _exports.isMachineOp = isMachineOp;
  _exports.isOp = isOp;
  /* This file is generated by build/debug.js */
  function isMachineOp(value) {
    return value >= 0 && value <= 15;
  }
  function isOp(value) {
    return value >= 16;
  }

  /**
   * Registers
   *
   * For the most part, these follows MIPS naming conventions, however the
   * register numbers are different.
   */
  // $0 or $pc (program counter): pointer into `program` for the next insturction; -1 means exit
  var $pc = 0; // $1 or $ra (return address): pointer into `program` for the return
  _exports.$pc = $pc;
  var $ra = 1; // $2 or $fp (frame pointer): pointer into the `evalStack` for the base of the stack
  _exports.$ra = $ra;
  var $fp = 2; // $3 or $sp (stack pointer): pointer into the `evalStack` for the top of the stack
  _exports.$fp = $fp;
  var $sp = 3; // $4-$5 or $s0-$s1 (saved): callee saved general-purpose registers
  _exports.$sp = $sp;
  var $s0 = 4;
  _exports.$s0 = $s0;
  var $s1 = 5; // $6-$7 or $t0-$t1 (temporaries): caller saved general-purpose registers
  _exports.$s1 = $s1;
  var $t0 = 6;
  _exports.$t0 = $t0;
  var $t1 = 7; // $8 or $v0 (return value)
  _exports.$t1 = $t1;
  var $v0 = 8;
  _exports.$v0 = $v0;
  function isLowLevelRegister(register) {
    return register <= $sp;
  }
  var SavedRegister;
  _exports.SavedRegister = SavedRegister;
  (function (SavedRegister) {
    SavedRegister[SavedRegister["s0"] = 4] = "s0";
    SavedRegister[SavedRegister["s1"] = 5] = "s1";
  })(SavedRegister || (_exports.SavedRegister = SavedRegister = {}));
  var TemporaryRegister;
  _exports.TemporaryRegister = TemporaryRegister;
  (function (TemporaryRegister) {
    TemporaryRegister[TemporaryRegister["t0"] = 6] = "t0";
    TemporaryRegister[TemporaryRegister["t1"] = 7] = "t1";
  })(TemporaryRegister || (_exports.TemporaryRegister = TemporaryRegister = {}));
});